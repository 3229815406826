/**
 * Environment for previewing before releasing for production. This environment used for user acceptance testing by Alteo.
 * Releases are using tagged versions and released on request from Alteo.
 *
 * !!! This environment communicates with PRODUCTION version of other integrations.
 */
export const environment = {
  enableAngularProdMode: true,
  reportErrors: true,
  environment: 'staging',
  frontendVersion: '1.1.1 (from v1.1.1 at 99f4f08)',
  /** These settings come from the main (production) Sinergy tenant in Azure. */
  azureADTenantID: '15833e6a-b99b-4bb8-b02a-d4ae48258b0c',
  azureADClientID: 'c4c16d0f-d6a8-4896-b967-2da26beda353',
  azureADApiAppURI: 'api://alteo-sinergy-platform-staging-api',
  apiBasePath: 'https://api.platform.staging.artemis-energy.com',
  pagination: {
    defaultPageLimit: 50,
  },
};
